@import "style/mixins.module.scss";

.root {
    position: fixed;
    bottom: 16px;
    right: 24px;
    height: 56px;
    z-index: calc(var(--z-index-fixed-buttons) + 1);

    @include respond-to("tablet") {
        bottom: 20px;
        right: 20px;
    }

    :global #wa-msg-us-btn {
        justify-content: center;
        width: 56px;
        height: 56px;
        padding: 16px 0 !important;
        border: none !important;
        box-shadow: 0 10px 32px -4px rgba(24, 39, 75, 0.1) !important;
        background-color: get_color("gray", "0") !important;
        background-image: url("/images/icons/whatsapp-widget.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 28px;
        border-radius: 50% !important;

        svg {
            display: none;
        }

        @include respond-to("laptop") {
            width: 56px;
            border-radius: 50% !important;
        }
    }

    &_source-from-landing {
        :global #wa-msg-us-btn {
            background-image: url("/images/icons/whatsapp-widget.svg");
        }
    }

    &_version-germany {
        :global #wa-msg-us-btn {
            @include respond-to("mobile") {
                min-width: 56px;
                border-radius: 50% !important;
            }
        }
    }
}

.hide {
    display: none;
}
