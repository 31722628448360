.question {
    position: relative;
    z-index: var(--z-index-base-layout);
}

@media (min-width: 768px) {
    .question {
        display: none;
    }
}
