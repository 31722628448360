@import "./tokens";

$breakpoints: (
    "mobile": (
        // max-width: 767px,
        max-width: 1023px,
    ),
    "mobile-L": (
        min-width: 375px,
    ),
    "tablet": (
        min-width: 768px,
    ),
    "laptop": (
        min-width: 1024px,
    ),
    "container-default": (
        min-width: 1288px,
    ),
    "desktop": (
        min-width: 1366px,
    ),
    "laptop-L": (
        min-width: 1440px,
    ),
    "laptop-XL": (
        min-width: 1600px,
    ),
) !default;

@mixin respond-to($breakpointList) {
    @each $breakpoint in $breakpointList {
        @if map-has-key($breakpoints, $breakpoint) {
            @media #{inspect(map-get($breakpoints, $breakpoint))} {
                @content;
            }
        } @else {
            @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
        + 'Available breakpoints are: #{map-keys($breakpoints)}.';
        }
    }
}

@mixin normalizedList {
    margin: 0;
    padding: 0;
    font-weight: normal;
    list-style: none;
}

@mixin normalizedButton {
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-weight: inherit;
}

@function get_color($palette, $color: "0") {
    $colorTokens: map_get($tokens, "color");
    $selected: map_get($colorTokens, $palette);

    @if $selected {
        @return map_get($selected, $color);
    } @else {
        @error "Key #{$palette} doesn't match tokens signature";
    }
}

@mixin color($palette, $color: "0") {
    color: get_color($palette, $color);
}

@mixin formTitle() {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 24px;
    max-width: 792px;

    @include respond-to("laptop") {
        margin-bottom: 40px;
    }

    &_black {
        @include color("gray", "-5");
    }

    &_ss-pro {
        font-family: "Source Serif Pro", serif;
    }

    &_germany {
        @include color("gray", "-7");
    }
}

.only-mobile {
    @include respond-to("laptop") {
        display: none !important;
    }
}

.only-desktop {
    @include respond-to("mobile") {
        display: none !important;
    }
}
