@import "style/mixins.module.scss";

@keyframes fadeInUpBig {
    from {
        transform: translateY(2000px);
    }

    to {
        transform: translateY(32px);
    }
}

.fade-in-up-big {
    animation-name: fadeInUpBig;
    animation-delay: 0.1s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: var(--z-index-modal);
    display: none;

    &_open {
        display: block;
    }

    &_has-blackout {
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.root {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 16px;
    padding-bottom: 16px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 32px);
    background-color: get_color("gray", "0");
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    transform: translateY(2000px);

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(66, 78, 88, 0.1);
        border-radius: 8px;
        width: 48px;
        height: 4px;
    }
}

.box {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
}

@media (min-width: 280px) {
    .overlay {
        height: 100%;
    }
}
